export default [
  {
    name: 'Consulta Aprovações de Temas',
    path: 'consulta-aprovacoes-temas',
    component: () => import('@/views/dados/consulta-aprovacoes-temas/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Consulta Aprovações de Temas', exact: false }
      ])
    }
  },
]