<template>
  <v-card class="v-card--wizard" elevation="12" max-width="700">
    <v-card-title class="justify-center display-2 font-weight-light pt-5">
      {{ title }}
    </v-card-title>

    <div
      v-if="subtitle"
      class="text-center display-1 grey--text font-weight-light"
    >
      {{ subtitle }}
    </div>

    <v-tabs
      id="wizard-tabs-etapas"
      ref="tabs"
      v-model="internalValue"
      background-color="grey lighten-3"
      color="white"
      class="mt-6"
      grow
      slider-size="65"
    >
      <v-tabs-slider class="mt-1" color="primary" />

      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :ripple="false"
        :disabled="!availableSteps.includes(i)"
      >
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-card-text class="mt-6">
      <v-tabs-items v-model="internalValue">
        <slot />
      </v-tabs-items>
    </v-card-text>

    <div id="wizard-prev-next">
      <v-card-actions class="pb-4 pa-4">
        <v-btn
          id="wizard-prev"
          :disabled="internalValue === 0"
          class="white--text"
          color="grey darken-2"
          min-width="125"
          @click="$emit('click:prev')"
        >
          Anterior
        </v-btn>

        <v-spacer />

        <v-btn
          :id="btnSaveCustomId"
          :disabled="!availableSteps.includes(internalValue + 1) || disabledBtnFinalizar"
          :loading="loading"
          color="primary"
          min-width="100"
          @click="$emit('click:next')"
        >
          {{ internalValue === tabs.length - 1 ? "Finalizar" : "Próximo" }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";

export default {
  name: "MaterialWizard",

  mixins: [Proxyable],

  props: {
    availableSteps: {
      type: Array,
      default: () => []
    },
    tabs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    disabledBtnFinalizar: {
      type: Boolean,
      default: false,
      required: false
    },
    btnSaveCustomId: {
      type: String,
      required: false,
      default: 'wizard-next'
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
  }
};
</script>

<style lang="sass">
.v-card--wizard
  overflow: visible

  .v-tabs-bar
    height: 56px !important
    padding: 0 0px
  
    .v-slide-group__wrapper
      display: -webkit-inline-flex !important

  .v-tabs-slider-wrapper
    overflow: visible

  .v-tabs-slider
    border-radius: 4px

  .v-tabs-slider-wrapper
    contain: initial
    z-index: 0
</style>