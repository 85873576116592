export default [{
  path: '/validacoes',
  component: () => import('@/views/Index'),
  children: [
    {
      name: 'Validações',
      path: 'dashboard',
      component: () => import('@/views/validacoes/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Validações de Indicadores' , to: '/validacoes', exact: false }
        ])
      }
    },
    {
      name: 'Validações Detalhes',
      path: 'visao-detalhada',
      component: () => import('@/views/validacoes/VisaoDetalhada.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Validações de Indicadores' , to: '/validacoes/dashboard', exact: true },
          { text: 'Visão Detalhada' , to: '/validacoes/visao-detalhada', exact: false },
        ])
      }
    }
  ]
}]