export default [{
  path: '/login',
  component: () => import('@/views/login/Index'),
  children: [
    {
      name: 'Login',
      path: '',
      component: () => import('@/views/login/Login')
    }
  ]
}]
