import IndicadoresServicosV2Service from '@/services/IndicadoresServicosV2Service'

const state = {
  isDirty: false,
  competencia: '',
  competenciaDe: '',
  competenciaAte: '',
  regionalSelecionada: null,
  tipoCalculo: 'lno_gdis',
  municipio: null, 
  indicadorOrdenacao: 'qlp', 
};

const getters = {
  getCompetencia: state => state.competencia,
  getCompetenciaDe: state => state.competenciaDe,
  getCompetenciaAte: state => state.competenciaAte,
  getRegionalSelecionada: state => state.regionalSelecionada,
  getTipoCalculo: state => state.tipoCalculo,
  getMunicipioSelecionado: state => state.municipio,
  getIndicadorOrdenacao: state => state.indicadorOrdenacao, 
};

const actions = {
  setRangeCompetencias({ commit, state }) {
    if (state.isDirty) {
      return;
    }

    IndicadoresServicosV2Service.getCompetenciaDadosRecentes()
      .then((competencia) => {
        commit('SET_COMPETENCIA', competencia.data.max_competencia);
        commit('SET_COMPETENCIA_DE', competencia.data.max_competencia);
        commit('SET_COMPETENCIA_ATE', competencia.data.max_competencia);
        commit('SET_IS_DIRTY', true);
      })
      .catch(err => {
        throw err;
      });
  },
  setCompetencia({ commit }, value) {
    commit('SET_COMPETENCIA', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
    commit('SET_IS_DIRTY', true);
  },
  setRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', value);
    commit('SET_IS_DIRTY', true);
  },
  setTipoCalculo({ commit }, value) {
    commit('SET_TIPO_CALCULO', value);
    commit('SET_IS_DIRTY', true);
  },
  setMunicipio({ commit }, value) { 
    commit('SET_MUNICIPIO', value);
    commit('SET_IS_DIRTY', true);
  },
  setIndicadorOrdenacao({ commit }, value) { 
    commit('SET_INDICADOR_ORDENACAO', value);
    commit('SET_IS_DIRTY', true);
  },
  resetRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', null);
  },
  resetMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', null);
  },
};

const mutations = {
  SET_IS_DIRTY: (state, value) => (state.isDirty = value),
  SET_COMPETENCIA: (state, value) => (state.competencia = value),
  SET_COMPETENCIA_DE: (state, value) => (state.competenciaDe = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competenciaAte = value),
  SET_REGIONAL_SELECIONADA: (state, value) => (state.regionalSelecionada = value),
  SET_TIPO_CALCULO: (state, value) => (state.tipoCalculo = value),
  SET_MUNICIPIO: (state, value) => (state.municipio = value), 
  SET_INDICADOR_ORDENACAO: (state, value) => (state.indicadorOrdenacao = value), 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
