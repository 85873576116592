import { onlyAdminUsers } from '@/router/guards'

export default [
  {
    name: 'Usuários Index',
    path: 'usuarios',
    component: () => import('@/views/administracao/usuarios/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Usuários', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
  {
    name: 'Usuários Editar',
    path: 'usuarios/edit/:id',
    component: () => import('@/views/administracao/usuarios/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Usuários', to: '/administracao/usuarios', exact: true },
        { text: 'Editar', exact: false },
      ])
    },
    beforeEnter: onlyAdminUsers
  },
  {
    name: 'Usuários cadastrar',
    path: 'usuarios/edit',
    component: () => import('@/views/administracao/usuarios/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Usuários', to: '/administracao/usuarios', exact: true },
        { text: 'Novo', exact: false },
      ])
    },
    beforeEnter: onlyAdminUsers
  },
]