import AuthService from '@/services/AuthService';
import store from '@/store.js';

const onlyAdminUsers = async (to, from, next) => {
    const userIsAdmin = await AuthService.userIsAdmin();
    if (!userIsAdmin) {
        next({ name: 'Home' });
    }
    next();
};

const onlyValidadorOrAdminUsers = (to, from, next) => {
    const userInfo = store.getters.getUserInfo;

    if (!userInfo.admin && !userInfo.validador) {
        next({ name: 'Home' });
    }
    
    next();
};

export { onlyAdminUsers, onlyValidadorOrAdminUsers };
