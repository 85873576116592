import IndicadoresComerciaisV2Service from '@/services/IndicadoresComerciaisV2Service.js'
import dayjs from 'dayjs';

const namespaced = true;

const state = {
  isDirty: false,
  competencia_de: "2023-01",
  competencia_ate: "2023-01",
  relatorioSelecionado: "Comercial",
  categoriaServicosSelecionada: 1,
  servicoSelecionado: 1
};

const getters = {
  getCompetenciaDe: state => state.competencia_de,
  getCompetenciaAte: state => state.competencia_ate,
  getRelatorioSelecionado: state => state.relatorioSelecionado,
  getCategoriaServicosSelecionada: state => state.categoriaServicosSelecionada,
  getServicoSelecionado: state => state.servicoSelecionado
};

const actions = {
  setRangeCompetencias({ commit, state }) {
    if (state.isDirty || state.relatorioSelecionado != 'Comercial') {
      return;
    }

    return IndicadoresComerciaisV2Service.getUltimaCompetencia()
      .then(res => {
        commit('SET_COMPETENCIA_ATE', dayjs(res.max_competencia).format('YYYY-MM'));
        commit('SET_IS_DIRTY', true);
      })
      .catch(err => {
        throw err;
      });
  },
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
    commit('SET_IS_DIRTY', true);
  },
  setRelatorioSelecionado({ commit }, value) {
    commit('SET_RELATORIO_SELECIONADO', value);
    commit('SET_IS_DIRTY', true);
  },
  setCategoriaServicosSelecionada({ commit }, value) {
    commit('SET_CATEGORIA_SERVICOS_SELECIONADA', value);
    commit('SET_IS_DIRTY', true);
  },
  setServicoSelecionado({ commit }, value) {
    commit('SET_SERVICO_SELECIONADO', value);
    commit('SET_IS_DIRTY', true);
  },
};

const mutations = {
  SET_IS_DIRTY: (state, value) => (state.isDirty = value),
  SET_COMPETENCIA_DE: (state, value) => (state.competencia_de = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competencia_ate = value),
  SET_RELATORIO_SELECIONADO: (state, value) => (state.relatorioSelecionado = value),
  SET_CATEGORIA_SERVICOS_SELECIONADA: (state, value) => (state.categoriaServicosSelecionada = value),
  SET_SERVICO_SELECIONADO: (state, value) => (state.servicoSelecionado = value),
};


export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};