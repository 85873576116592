let Tawk = {}
Tawk.install = function (Vue, options) {
  Vue.prototype.$Tawk = {}
  Vue.prototype.$Tawk.$startChat = function () {
    let Tawk_API = {}
    let Tawk_LoadStart = new Date()
    let s1 = document.createElement("script")
    let s0 = document.getElementsByTagName("script")[0]
    s1.async = true
    s1.src = options.tawkSrc
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
    window.Tawk_API = Tawk_API

    /*Tawk_API.onLoad = function(){
      Vue.prototype.$Tawk.$hideWidget()
    }*/
  }
  
  Vue.prototype.$Tawk.$updateChatUser = function (user) {
    if (!user) return
    
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes({
        'name': user.name,
        'email': user.email,
        'hash': user.hash
      }, function (error) {
        console.log(error)
      })
    }
  }
  Vue.prototype.$Tawk.$endChat = function () {
    window.Tawk_API.endChat()
  }
  Vue.prototype.$Tawk.$logout = function () {
    window.Tawk_API.logout()
  }
  Vue.prototype.$Tawk.$shutdown = function () {
    window.Tawk_API.shutdown()
  }
  /*
  Vue.prototype.$Tawk.$endTawk = function () {
    // Esse metodo está incompleto, está dando erro no som da notificação, não achei nenhuma forma de desliga-lá apos o tawk-to ser carregado 
    // Finaliza a conversa
    window.Tawk_API.endChat();
    
    setTimeout(() => {
      // Minimiza o chat widget
      window.Tawk_API.minimize();
      
      // limpa instância do Tawk_API
      Tawk_API = {}

      // Remove os atributos do tawk window
      delete window.$_Tawk_AccountKey;
      delete window.$_Tawk_WidgetId;
      delete window.$_Tawk_Unstable;

      // Remove o iframe do tawk
      document.querySelector('iframe[title="chat widget"]').remove();
    }, 300);
  }
  */
  Vue.prototype.$Tawk.$hideWidget = function () {
    window.Tawk_API.hideWidget()
  }
  Vue.prototype.$Tawk.$showWidget = function () {
    window.Tawk_API.showWidget()
  }
  Vue.prototype.$Tawk.$toggleVisibility = function () {
    window.Tawk_API.toggleVisibility()
  }
  Vue.prototype.$Tawk.$popup = function () {
    window.Tawk_API.popup()
  }
  Vue.prototype.$Tawk.$isInit = function () {
    if (window.Tawk_API) {
      return true
    }
    return false
  }
}
export default Tawk
