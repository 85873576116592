import { onlyValidadorOrAdminUsers } from '@/router/guards'

export default [
  {
    name: 'Manutenção Técnicos',
    path: 'manutencao-tecnicos',
    component: () => import('@/views/dados/manutencao-tecnicos/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Manutenção AL/SE/LD', exact: false }
      ])
    }
  },
  {
    name: 'Manutenção Técnicos Importacões Index',
    path: 'manutencao-tecnicos/importacoes',
    component: () => import('@/views/dados/manutencao-tecnicos/importacoes/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Manutenção AL/SE/LD', to: '/dados/manutencao-tecnicos', exact: true },
        { text: 'Importações', to: '/dados/manutencao-tecnicos/importacoes', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
  {
    name: 'Manutenção Técnicos Importacões Add',
    path: 'manutencao-tecnicos/importacoes/add',
    component: () => import('@/views/dados/manutencao-tecnicos/importacoes/Add.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Manutenção AL/SE/LD', to: '/dados/manutencao-tecnicos', exact: true },
        { text: 'Importações', to: '/dados/manutencao-tecnicos/importacoes', exact: true },
        { text: 'Nova Importação', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
  {
    name: 'Manutenção Técnicos Importacões Logs',
    path: 'manutencao-tecnicos/importacoes/logs/:id',
    component: () => import('@/views/dados/manutencao-tecnicos/importacoes/Logs.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados', to: '/dados', exact: true },
        { text: 'Manutenção AL/SE/LD', to: '/dados/manutencao-tecnicos', exact: true },
        { text: 'Importações', to: '/dados/manutencao-tecnicos/importacoes', exact: true },
        { text: 'Logs', exact: false },
      ])
    },
    beforeEnter: onlyValidadorOrAdminUsers
  },
]