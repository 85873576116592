import { onlyAdminUsers } from '@/router/guards'

export default [{
      name: 'Governança',
      path: 'governanca',
      component: () => import('@/views/administracao/governanca/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Governança', exact: false }
        ])
      },
      beforeEnter: onlyAdminUsers
    },
 
]