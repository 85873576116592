import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store.js'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueIziToast from 'vue-izitoast'
import VueTheMask from 'vue-the-mask'
import '@/filters.js'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import 'izitoast/dist/css/iziToast.min.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet.fullscreen/Control.FullScreen'
import 'leaflet.fullscreen/Control.FullScreen.css'
import 'leaflet.control.select/dist/leaflet.control.select.js'
import 'leaflet.control.select/dist/leaflet.control.select.css'
import 'leaflet-control-geocoder'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'
import 'spin.js'
import 'leaflet-spin'
import Tawk from './plugins/tawk';

Vue.config.productionTip = false

Vue.use(VueIziToast)

Vue.use(VueTheMask)

Highcharts.setOptions({
  lang: {
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    loading: 'Carregando gráfico...',
    decimalPoint: ',',
    thousandsSep: '.',
    resetZoom: 'Limpar Zoom',
    resetZoomTitle: 'Voltar Zoom para nível 1:1',
    noData: 'Sem dados para exibição',
  },
  exporting:{
    enabled: false
  }
});
Vue.use(HighchartsVue, {
	highcharts: Highcharts
});

Vue.use(Tawk, {
  tawkSrc: process.env.VUE_APP_TAWK_EMBED_URL
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')