import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('parseToDateTimeBR', str => {
    if (!str) str = '-';
    return (str && str.length >= 10) ? dayjs(str).format('DD/MM/YYYY HH:mm') : str;
});

Vue.filter('parseToDateBR', str => {
    return (str && str.length >= 10) ? dayjs(str).format('DD/MM/YYYY') : str;
});

Vue.filter('parseToCustomDateFormat', (str, format) => {
    return (str && str.length >= 10) ? dayjs(str).format(format) : str;
});

Vue.filter('parseNumberToIntegerBR', num => {
    return parseInt(num).toLocaleString('pt-BR');
});

Vue.filter('parseNumberToFloatBR', num => {
    return parseFloat(num).toLocaleString('pt-BR');
});

Vue.filter('parseNumberToFloatBrIgnoringNaN', value => {
    const parsedValue = parseFloat(value).toLocaleString('pt-BR');

    return parsedValue === 'NaN' ? value : parsedValue;
}); 

Vue.filter('toUpperCase', str => {
    return str.toUpperCase();
});

Vue.filter('toLowerCase', str => {
    return str.toLowerCase();
});

Vue.filter('addLeadingZero', num => {
    return (Number(num) <= 9 ? '0' : '') + num;
});

Vue.filter('formatToMonth', data => {
    if (!data) return '-';
    const [year, month] = data.split('-');
    return `${month}/${year}`;
});

Vue.filter('bytesToSize', bytes => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
});

Vue.filter('truncate', (text, size) => {
    if (text.length <= size) return text;
    return text.substring(0, size) + '...';
});