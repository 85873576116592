import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  email,
  max,
  min,
  min_value,
  max_value,
  numeric,
  required,
} from 'vee-validate/dist/rules'

extend('email', email)

extend('max', max)

extend('min', {
  ...min,
  message: 'O valor do campo está inválido'
});

extend('min_value', {
  ...min_value,
  message: `O valor do campo está inválido`
});

extend('max_value', {
  ...max_value,
  message: `O valor do campo está inválido`
});

extend('numeric', {
  ...numeric,
  message: 'Este campo deve ser um valor númerico',
});

extend('required', {
  ...required,
  message: 'Este campo é obrigatório'
});

extend('required_if', {
  ...required,
  message: 'Este campo é obrigatório'
});

extend('email', {
  ...email,
  message: 'Este e-mail não é válido'
});

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)