export default [{
  path: '/perfil',
  component: () => import('@/views/Index'),
  children: [
    {
      name: 'Visualizar Perfil',
      path: 'index',
      component: () => import('@/views/perfil/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Perfil', exact: false }
        ])
      },
    },
    {
      name: 'Logout',
      path: 'logout',
      component: () => import('@/views/perfil/Logout.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
}]