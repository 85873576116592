import AuthService from '@/services/AuthService'
import TourService from '@/services/TourService'

const initialState = {
  id: null,
  name: null,
  login: null
}

const state = {
  user: initialState,
  userInfo: initialState,
  ambiente: '',
  tour: []
}

const getters = {
  getUser: state => state.user,
  getUserInfo: state => state.userInfo,
  getUserId: state => state.user.id,
  getUserName: state => state.user.name,
  getUserLogin: state => state.user.login,
  getAmbiente: state => state.ambiente,
  getTourInfo: state => state.tour,
}

const actions = {
  
  setUser({ commit }, value) {
    commit('SET_USER', value)
  },

  setUserInfo({ commit }, value) {
    commit('SET_USER_INFO', value)
  },

  setTour({ commit }, value) {
    commit('SET_TOUR', value)
  },

  async login({ commit }, value) {
    const { login, password, companyId } = value;
    await AuthService.login(login, password, companyId);

    const user = await AuthService.getLoggedUser();
    const user_info = await AuthService.getUserInfo();
    const ambiente = await AuthService.getAmbienteAplicacao();
    const tour_info = await TourService.getRelacaoTourUser();

    commit('SET_TOUR', tour_info);
    commit('SET_AMBIENTE', ambiente);
    commit('SET_USER', user);
    commit('SET_USER_INFO', user_info);
  },

  async logout({ commit }) {
    AuthService.logout()
    commit('SET_USER', initialState)
  }
}

const mutations = {
  SET_USER: (state, value) => (state.user = value),
  SET_USER_INFO: (state, value) => (state.userInfo = value),
  SET_TOUR: (state, value) => (state.tour = value),
  SET_AMBIENTE: (state, value) => (state.ambiente = value),
}

export default {
  state,
  getters,
  actions,
  mutations,
}