export default [{
  path: '/powerups',
  component: () => import('@/views/Index'),
  children: [
    {
      name: 'Power Ups',
      path: '',
      component: () => import('@/views/power-ups/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Power Ups' , to: '/power-ups', exact: false }
        ])
      }
    }
  ]
}]