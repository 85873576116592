import APIService from './APIService';

export default {
  getQtdErrosCriticosPorTema(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-qtd-erros-por-tema?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosPorCompetencia(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-por-competencia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosPorAno(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-por-ano?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosComValidacoes(competenciaDe, competenciaAte, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-com-validacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosConsolidados(competenciaDe, competenciaAte, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-consolidados?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosValidacoesAgregadas(competenciaDe, competenciaAte, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-validacoes-agregadas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getStatusAprovacoesByIndicador(competenciaDe, competenciaAte, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-status-aprovacoes-by-indicador?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosAprovacoesIndicadorByAnoAndMes(indicador, ano, mes, versionId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-dados-aprovacoes-indicador-by-ano-and-mes?indicador=${indicador}&ano=${ano}&mes=${mes}&version_id=${versionId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  salvarAprovacoesValidacoes(postData) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post('indicadores-comerciais-v2/salvar-aprovacoes-validacoes', JSON.stringify(postData))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  downloadArquivoValidacoesVisaoMunicipio(competenciaDe, competenciaAte, temasSelecionados, config) {
    return APIService.apiCall().get(`indicadores-comerciais-v2/download-arquivo-validacoes-visao-municipio?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`, {
      responseType: 'blob',  
      ...config
    });
  },
  downloadArquivoValidacoesVisaoConsolidada(competenciaDe, competenciaAte, temasSelecionados, config) {
    return APIService.apiCall().get(`indicadores-comerciais-v2/download-arquivo-validacoes-visao-consolidada?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getNomeArquivoValidacoes() {
    return APIService.apiCall().get(`indicadores-comerciais-v2/get-nome-arquivo-validacoes`);
  },
  getUltimaCompetencia() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-comerciais-v2/get-ultima-competencia`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  downloadArquivoLogsAprovacoes(competenciaDe, competenciaAte, config) {
    return APIService.apiCall().get(`indicadores-comerciais-v2/download-arquivo-logs-aprovacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`, {
      responseType: 'blob',  
      ...config
    });
  },
}