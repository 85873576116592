const namespaced = true;

const state = {
  competencia_de: "2024-01",
  competencia_ate: "2024-01",
};

const getters = {
  getCompetenciaDe: state => state.competencia_de,
  getCompetenciaAte: state => state.competencia_ate,
};

const actions = {
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
  },
};

const mutations = {
  SET_COMPETENCIA_DE: (state, value) => (state.competencia_de = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competencia_ate = value),
};


export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};