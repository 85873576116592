export default [{
  path: '/home',
  component: () => import('@/views/Index'),
  children: [
    {
      name: 'Home',
      path: '',
      redirect: to => {
        return { name: "Validações" };
      },
      component: () => import('@/views/Home'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Dashboard', exact: false }
        ])
      }
    }
  ]
}]