const namespaced = true;

const state = {
  count_pendencias_not_done: null,
};

const getters = {
  getCountPendenciasNotDone: state => state.count_pendencias_not_done
};

const actions = {
  setCountPendenciasNotDone({ commit }, value) {
    commit('SET_COUNT_PENDENCIAS_NOT_DONE', value);
  },
};

const mutations = {
  SET_COUNT_PENDENCIAS_NOT_DONE: (state, value) => (state.count_pendencias_not_done = value),
};


export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};