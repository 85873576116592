import { render, staticRenderFns } from "./MapaMaranhaoIcon.vue?vue&type=template&id=361ed18d&"
import script from "./MapaMaranhaoIcon.vue?vue&type=script&lang=js&"
export * from "./MapaMaranhaoIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports