import IndicadoresComerciaisV2Service from "@/services/IndicadoresComerciaisV2Service.js";
import dayjs from "dayjs";

const state = {
  isDirty: false,
  competenciaDe: '',
  competenciaAte: '',
  competencia: '',
  regionalSelecionada: null,
  municipio: null,
  kpiOrdenacao: 'qrt',
};

const getters = {
  getCompetenciaDe: (state) => state.competenciaDe,
  getCompetenciaAte: (state) => state.competenciaAte,
  getCompetencia: (state) => state.competencia,
  getRegionalSelecionada: (state) => state.regionalSelecionada,
  getMunicipio: (state) => state.municipio,
  getKpiOrdenacao: (state) => state.kpiOrdenacao,
};

const actions = {
  setRangeCompetencias({ commit, state }) {
    if (state.isDirty) {
      return;
    }

    IndicadoresComerciaisV2Service.getUltimaCompetencia()
      .then((competencia) => {
        const competenciaRecente = dayjs(competencia.max_competencia).format('YYYY-MM');
        commit('SET_COMPETENCIA', competenciaRecente);
        commit('SET_COMPETENCIA_DE', competenciaRecente);
        commit('SET_COMPETENCIA_ATE', competenciaRecente);
        commit('SET_IS_DIRTY', true);
      })
      .catch(err => {
        throw err;
      });
  },
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetencia({ commit }, value) {
    commit('SET_COMPETENCIA', value);
    commit('SET_IS_DIRTY', true);
  },
  setRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', value);
    commit('SET_IS_DIRTY', true);
  },
  setMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', value);
    commit('SET_IS_DIRTY', true);
  },
  setKpiOrdenacao({ commit }, value) {
    commit('SET_KPI_ORDENACAO', value);
    commit('SET_IS_DIRTY', true);
  },
  resetRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', null);
  },
  resetMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', null);
  },
};

const mutations = {
  SET_IS_DIRTY: (state, value) => (state.isDirty = value),
  SET_COMPETENCIA_DE: (state, value) => (state.competenciaDe = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competenciaAte = value),
  SET_COMPETENCIA: (state, value) => (state.competencia = value),
  SET_REGIONAL_SELECIONADA: (state, value) => (state.regionalSelecionada = value),
  SET_MUNICIPIO: (state, value) => (state.municipio = value),
  SET_KPI_ORDENACAO: (state, value) => (state.kpiOrdenacao = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
