import { onlyAdminUsers } from '@/router/guards'

export default [{
      name: 'Importações INDGER',
      path: 'importacoes-indger/historico',
      component: () => import('@/views/administracao/importacoes-indger/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações INDGER', exact: false }
        ])
      },
      beforeEnter: onlyAdminUsers
    },
    {
      name: 'Importações INDGER Adicionar',
      path: 'importacoes-indger/add',
      component: () => import('@/views/administracao/importacoes-indger/Add.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações INDGER', to: '/administracao/importacoes-indger/historico', exact: true },
          { text: 'Adicionar', exact: false },
        ])
      },
      beforeEnter: onlyAdminUsers
    },
    {
      name: 'Importações INDGER Logs',
      path: 'importacoes-indger/logs/:id',
      component: () => import('@/views/administracao/importacoes-indger/Logs.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações INDGER', to: '/administracao/importacoes-indger/historico', exact: true },
          { text: 'Logs', exact: false },
        ])
      },
      beforeEnter: onlyAdminUsers
    }
]