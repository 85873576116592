export default [
  {
    path: '/redefinir-senha',
    component: () => import('@/views/redefinir-senha/Index.vue'),
    children: [
      {
        name: 'Redefinir Senha',
        path: '',
        component: () => import('@/views/redefinir-senha/RedefinirSenha.vue')
      }
    ]
  }
];
