export default [
  {
    name: 'Indger Exports Index',
    path: 'exportacoes-indger/historico',
    component: () => import('@/views/dados/exports/Historico.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados' , to: '/dados', exact: true },
        { text: 'Exportações do INDGER' , exact: false },
      ])
    }
  },
  {
    name: 'Add Indger Exports',
    path: 'exportacoes-indger/edit',
    component: () => import('@/views/dados/exports/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados' , to: '/dados', exact: true },
        { text: 'Exportações do INDGER' , to: '/dados/exportacoes-indger/historico',exact: true },
        { text: 'Adicionar' , exact: false },
      ])
    }
  },
  {
    name: 'Edit Indger Exports',
    path: 'exportacoes-indger/edit/:id',
    component: () => import('@/views/dados/exports/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Dados' , to: '/dados', exact: true },
        { text: 'Exportações do INDGER' , to: '/dados/exportacoes-indger/historico',exact: true },
        { text: 'Editar' , exact: false },
      ])
    }
  },
]