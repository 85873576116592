import Vue from 'vue'
import Router from 'vue-router'
import HtmlTitleManager from '@/utils/htmlTitleManager';
import AuthService from '@/services/AuthService'
import routes from '@/router/routes'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const userIsAuthenticated = store => {
  const { getUserId, getCompanyId } = store.getters
  const token = AuthService.getLocalToken()
  return getUserId && getCompanyId && token
}

const waitForStorageToBeReady = async (to, from, next) => {

  await store.restored

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = userIsAuthenticated(store)

  if (requiresAuth && !isAuthenticated) {
    return next({ name: 'Login' })
  }

  return next()
}
router.beforeEach(waitForStorageToBeReady)

router.afterEach(() => {
  HtmlTitleManager.changeTitle();
});

export default router