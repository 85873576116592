export default [{
  component: () => import('@/views/Index'),
  path: '/caixa-de-entrada',

  children: [
    {
      component: () => import('@/views/caixaDeEntrada/Index.vue'),
      name: 'Caixa de Entrada',
      path: '/',
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Caixa de Entrada', to: '', exact: false }
        ])
      }
    }

  ]
}]