import { onlyAdminUsers } from '@/router/guards'

export default [
  {
    name: 'Tipos de serviços',
    path: 'tipos-servicos',
    component: () => import('@/views/administracao/tipos-servicos/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Tipos de serviços', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
  {
    name: 'Adicionar tipos de serviços',
    path: 'tipos-servicos/add',
    component: () => import('@/views/administracao/tipos-servicos/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Tipos de serviços', to: '/administracao/tipos-servicos', exact: true },
        { text: 'Adicionar tipos de serviços', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
  {
    name: 'Editar tipos de serviços',
    path: 'tipos-servicos/edit/:id',
    component: () => import('@/views/administracao/tipos-servicos/Edit.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Tipos de serviços', to: '/administracao/tipos-servicos', exact: true },
        { text: 'Editar tipos de serviços', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
]