const state = {
  competenciaDe: '2024-01',
  competenciaAte: '2024-07',
  regionalSelecionada: null,
  municipio: null,
  niveis: [],
  tipologias: [],
  subtipologias: [],
  canais: [],
};

const getters = {
  getCompetenciaDe: state => state.competenciaDe,
  getCompetenciaAte: state => state.competenciaAte,
  getRegionalSelecionada: state => state.regionalSelecionada,
  getMunicipio: state => state.municipio,
  getNiveis: state => state.niveis,
  getTipologias: state => state.tipologias,
  getSubtipologias: state => state.subtipologias,
  getCanais: state => state.canais,
};

const actions = {
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
  },
  setRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', value);
  },
  setMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', value);
  },
  setNiveis({ commit }, value) {
    commit('SET_NIVEIS', value);
  },
  setTipologias({ commit }, value) {
    commit('SET_TIPOLOGIAS', value);
  },
  setSubtipologias({ commit }, value) {
    commit('SET_SUBTIPOLOGIAS', value);
  },
  setCanais({ commit }, value) {
    commit('SET_CANAIS', value);
  },
  resetRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', null);
  },
  resetMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', null);
  },
};

const mutations = {
  SET_COMPETENCIA_DE: (state, value) => (state.competenciaDe = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competenciaAte = value),
  SET_REGIONAL_SELECIONADA: (state, value) => (state.regionalSelecionada = value),
  SET_MUNICIPIO: (state, value) => (state.municipio = value),
  SET_NIVEIS: (state, value) => (state.niveis = value),
  SET_TIPOLOGIAS: (state, value) => (state.tipologias = value),
  SET_SUBTIPOLOGIAS: (state, value) => (state.subtipologias = value),
  SET_CANAIS: (state, value) => (state.canais = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
