<template>
  <router-view />
</template>

<script>

import AuthService from '@/services/AuthService';

export default {
  name: 'App',
  mounted() {
    setInterval(
      () => AuthService.refreshToken(),
      30 * 60 * 1000 // Atualiza o token a cada 30min
    );
  }
};

</script>

<style>
@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
</style>