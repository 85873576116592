import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/i18n';
import '@/sass/overrides.sass';

Vue.use(Vuetify);

const theme = {
  primary: "#550899",
  secondary: "#1B1B1B",
  accent: "#550899",

  error: "#df2c14",
  info: "#2B60D6",
  success: "#518E16",
  warning: "#e27602",
}

import MapaBrasilIcon from "@/components/customIcons/MapaBrasilIcon.vue";
import MapaRioGrandeSulIcon from "@/components/customIcons/MapaRioGrandeSulIcon.vue";
import MapaAlagoasIcon from "@/components/customIcons/MapaAlagoasIcon.vue";
import MapaGoiasIcon from "@/components/customIcons/MapaGoiasIcon.vue";
import MapaAmapaIcon from "@/components/customIcons/MapaAmapaIcon.vue";
import MapaPiauiIcon from "@/components/customIcons/MapaPiauiIcon.vue";
import MapaParaIcon from "@/components/customIcons/MapaParaIcon.vue";
import MapaMaranhaoIcon from "@/components/customIcons/MapaMaranhaoIcon.vue";

export default new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
      themes: {
        dark: theme,
        light: theme,
      },
    },
    icons: {
      values: {
        mapa_brasil: {
          component: MapaBrasilIcon
        },
        mapa_su: {
          component: MapaRioGrandeSulIcon
        },
        mapa_rs: {
          component: MapaRioGrandeSulIcon
        },
        mapa_al: {
          component: MapaAlagoasIcon
        },
        mapa_go: {
          component: MapaGoiasIcon
        },
        mapa_am: {
          component: MapaAmapaIcon
        },
        mapa_ap: {
          component: MapaAmapaIcon
        },
        mapa_pi: {
          component: MapaPiauiIcon
        },
        mapa_pa: {
          component: MapaParaIcon
        },
        mapa_ma: {
          component: MapaMaranhaoIcon
        }
      }
    },
  })
