import APIService from './APIService';

export default {
  getPrazoReguladoServico(servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-prazo-regulado-servico?servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getCategoriasServicos() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-categorias-servicos`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getCompetenciaDadosRecentes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-competencia-dados-recentes`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getSomatorioGeral(competenciaDe, competenciaAte,tipo_servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-somatorio-geral?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_servico=${tipo_servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCardRealizados(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-dados-card-realizados?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCardPendentes(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-dados-card-pendentes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCompensacoes(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-dados-compensacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosEntrantes(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-dados-entrantes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosSuspensos(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-dados-suspensos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getQtdTiposValidacaoGroupByServico(competenciaDe, competenciaAte, temasSelecionados, categoriasServicos) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-qtd-tipos-validacao-group-by-servico?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}&categorias_servicos=${categoriasServicos}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getStatusAprovacoesServicos(competenciaDe, competenciaAte, temasSelecionados, categoriasServicos) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-status-aprovacoes-servicos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}&categorias_servicos=${categoriasServicos}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getIndicadoresGroupByServico(competenciaDe, competenciaAte, temasSelecionados, categoriasServicos) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-indicadores-group-by-servico?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}&categorias_servicos=${categoriasServicos}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getValidacoesByServico(competenciaDe, competenciaAte, servico, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`indicadores-servicos-v2/get-validacoes-by-servico?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&temas_selecionados=${temasSelecionados}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  salvarCienteValidacao(postData) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post('indicadores-servicos-v2/salvar-ciente-validacao', JSON.stringify(postData))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  downloadArquivoValidacoes(competenciaDe, competenciaAte, temasSelecionados, categoriasServicos, config) {
    return APIService.apiCall().get(`indicadores-servicos-v2/download-arquivo-validacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}&categorias_servicos=${categoriasServicos}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getNomeArquivoValidacoes() {
    return APIService.apiCall().get(`indicadores-servicos-v2/get-nome-arquivo-validacoes`);
  },
  downloadArquivoLogsAprovacoes(competenciaDe, competenciaAte, servico, config) {
    return APIService.apiCall().get(`indicadores-servicos-v2/download-arquivo-logs-aprovacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&tipo_servico=${servico}`, {
      responseType: 'blob',  
      ...config
    });
  },
}