import home from './home'
import login from './login'
import esqueceuSenha from './esqueceu-senha'
import redefinirSenha from './redefinir-senha'
import perfil from './perfil'
import relatorios from './relatorios'
import powerUps from './power-ups'
import administracao from './administracao'
import validacoes from './validacoes'
import paineisGerenciais from './paineis-gerenciais'
import governanca from './governanca'
import dados from './dados'
import faq from './faq'
import caixaDeEntrada from './caixa-de-entrada'

let routes = []
routes = routes.concat(
  home,
  login,
  esqueceuSenha,
  redefinirSenha,
  perfil,
  // relatorios,
  powerUps,
  administracao,
  validacoes,
  paineisGerenciais,
  // governanca,
  dados,
  // faq,
  caixaDeEntrada
)

routes.push({
  path: '/',
  redirect: {
    name: 'Login'
  }
});

routes.push({
  path: '*',
  component: () => import('@/views/pages/Index'),
  children: [
    {
      name: '404 Error',
      path: '',
      component: () => import('@/views/pages/Error'),
    },
  ],
});

export default routes