import { onlyAdminUsers } from '@/router/guards'

export default [
  // {
  //   name: 'Relatórios Confguracoes',
  //   path: 'relatorios',
  //   component: () => import('@/views/administracao/relatorios/Index.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: (route) => ([
  //       { text: 'Administração', to: '/administracao', exact: true },
  //       { text: 'Relatórios', exact: false }
  //     ])
  //   },
  //   beforeEnter: onlyAdminUsers
  // }
]