const state = {
  competenciaDe: '2024-01',
  competenciaAte: '2024-06',
  regionalSelecionada: null,
  municipio: null,
  inspecoes: [],
  medicoes: [],
  origemMedicoes: [],
  tensaoReferencia: [],
  localizacaoUc: [],
  indicadorSelecionado: 'qrt',
};

const getters = {
  getCompetenciaDe: state => state.competenciaDe,
  getCompetenciaAte: state => state.competenciaAte,
  getRegionalSelecionada: state => state.regionalSelecionada,
  getMunicipio: state => state.municipio,
  getInspecoes: state => state.inspecoes,
  getMedicoes: state => state.medicoes,
  getOrigemMedicoes: state => state.origemMedicoes,
  getTensaoReferencia: state => state.tensaoReferencia,
  getLocalizacaoUc: state => state.localizacaoUc,
  getIndicadorSelecionado: state => state.indicadorSelecionado,
};

const actions = {
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
  },
  setRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', value);
  },
  setMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', value);
  },
  setInspecoes({ commit }, value) {
    commit('SET_INSPECOES', value);
  },
  setMedicoes({ commit }, value) {
    commit('SET_MEDICOES', value);
  },
  setOrigemMedicoes({ commit }, value) {
    commit('SET_ORIGEM_MEDICOES', value);
  },
  setTensaoReferencia({ commit }, value) {
    commit('SET_TENSAO_REFERENCIA', value);
  },
  setLocalizacaoUc({ commit }, value) {
    commit('SET_LOCALIZACAO_UC', value);
  },
  setIndicadorSelecionado({ commit }, value) {
    commit('SET_INDICADOR_SELECIONADO', value);
  },
  resetRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', null);
  },
  resetMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', null);
  },
};

const mutations = {
  SET_COMPETENCIA_DE: (state, value) => (state.competenciaDe = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competenciaAte = value),
  SET_REGIONAL_SELECIONADA: (state, value) => (state.regionalSelecionada = value),
  SET_MUNICIPIO: (state, value) => (state.municipio = value),
  SET_INSPECOES: (state, value) => (state.inspecoes = value),
  SET_MEDICOES: (state, value) => (state.medicoes = value),
  SET_ORIGEM_MEDICOES: (state, value) => (state.origemMedicoes = value),
  SET_TENSAO_REFERENCIA: (state, value) => (state.tensaoReferencia = value),
  SET_LOCALIZACAO_UC: (state, value) => (state.localizacaoUc = value),
  SET_INDICADOR_SELECIONADO: (state, value) => (state.indicadorSelecionado = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
