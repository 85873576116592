import router from '@/router/index'
import Store from '@/store';

export default {
async  changeTitle() {
    let breadCrumbList = await router.history.current.meta.breadcrumb();
    if (!breadCrumbList) return 'INDGER';
    
    let aux = []
    breadCrumbList.forEach(element => {
      aux.push(element.text)
    });
    document.title ='INDGER :: ' + Store.getters.getCompanyAcronym + ' :: ' + aux.join(" > ")
  }
};