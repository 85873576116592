import { onlyAdminUsers } from '@/router/guards'

export default [
  {
    name: 'Versionamento de Bases Index',
    path: 'versionamento-bases',
    component: () => import('@/views/administracao/versionamento-bases/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Versionamento de Bases', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
  {
    name: 'Versionamento de Bases Versões Ativas',
    path: 'versionamento-bases/versoes-ativas',
    component: () => import('@/views/administracao/versionamento-bases/VersoesAtivas.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', to: '/administracao', exact: true },
        { text: 'Versionamento de Bases', to: '/administracao/versionamento-bases', exact: true },
        { text: 'Versões Ativas', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  },
]