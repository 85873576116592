import { onlyAdminUsers } from '@/router/guards'

export default [
  // {
  //   name: 'Relatórios Criados Confguracoes',
  //   path: 'relatorios-criados-status-falha',
  //   component: () => import('@/views/administracao/relatorios-criados/Index.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: (route) => [
  //       { text: 'Administração', to: '/administracao', exact: true },
  //       { text: 'Relatórios :: Status Falha', exact: false }
  //     ]
  //   },
  //   beforeEnter: onlyAdminUsers
  // }
]