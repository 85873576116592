import APIService from './APIService';

export default {
  getRelacaoTourUser() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get('/tour/index')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },
  desativarTourUserCurrentPage(nomePagina) {
    return APIService.apiCall().post(`/tour/desativar-tour/${nomePagina}`,)
  }
}