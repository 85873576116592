import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

const state = {
  isDirty: false,
  competenciaDe: '',
  competenciaAte: '',
  competencia: '',
  categoriaServicosSelecionada: -1,
  servicoSelecionado: -1,
  regionalSelecionada: null,
  municipio: null,
  kpiOrdenacao: 'pendentes',
};

const getters = {
  getCompetenciaDe: state => state.competenciaDe,
  getCompetenciaAte: state => state.competenciaAte,
  getCompetencia: state => state.competencia,
  getCategoriaServicosSelecionada: state => state.categoriaServicosSelecionada,
  getServicoSelecionado: state => state.servicoSelecionado,
  getRegionalSelecionada: state => state.regionalSelecionada,
  getMunicipio: state => state.municipio,
  getKpiOrdenacao: state => state.kpiOrdenacao,
};

const actions = {
  setRangeCompetencias({ commit, state }) {
    if (state.isDirty) {
      return;
    }

    IndicadoresServicosV2Service.getCompetenciaDadosRecentes()
      .then((competencia) => {
        commit('SET_COMPETENCIA', competencia.data.max_competencia);
        commit('SET_COMPETENCIA_DE', competencia.data.max_competencia);
        commit('SET_COMPETENCIA_ATE', competencia.data.max_competencia);
        commit('SET_IS_DIRTY', true);
      })
      .catch(err => {
        throw err;
      });
  },
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetencia({ commit }, value) {
    commit('SET_COMPETENCIA', value);
    commit('SET_IS_DIRTY', true);
  },
  setCategoriaServicosSelecionada({ commit }, value) {
    commit('SET_CATEGORIA_SERVICOS_SELECIONADA', value);
    commit('SET_IS_DIRTY', true);
  },
  setServicoSelecionado({ commit }, value) {
    commit('SET_SERVICO_SELECIONADO', value);
    commit('SET_IS_DIRTY', true);
  },
  setRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', value);
    commit('SET_IS_DIRTY', true);
  },
  setMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', value);
    commit('SET_IS_DIRTY', true);
  },
  setKpiOrdenacao({ commit }, value) {
    commit('SET_KPI_ORDENACAO', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetencia({ commit }, value) {
    commit('SET_COMPETENCIA', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaDe({ commit }, value) {
    commit('SET_COMPETENCIA_DE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCompetenciaAte({ commit }, value) {
    commit('SET_COMPETENCIA_ATE', value);
    commit('SET_IS_DIRTY', true);
  },
  setCategoriaServicos({ commit }, value) {
    commit('SET_CATEGORIA_SERVICOS', value);
    commit('SET_IS_DIRTY', true);
  },
  setServico({ commit }, value) {
    commit('SET_SERVICO', value);
    commit('SET_IS_DIRTY', true);
  },
  setRegional({ commit }, value) {
    commit('SET_REGIONAL', value);
    commit('SET_IS_DIRTY', true);
  },
  setMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', value);
    commit('SET_IS_DIRTY', true);
  },
  setKpiOrdenacao({ commit }, value) {
    commit('SET_KPI_ORDENACAO', value);
    commit('SET_IS_DIRTY', true);
  },
  resetRegionalSelecionada({ commit }, value) {
    commit('SET_REGIONAL_SELECIONADA', null);
  },
  resetMunicipio({ commit }, value) {
    commit('SET_MUNICIPIO', null);
  },
};

const mutations = {
  SET_IS_DIRTY: (state, value) => (state.isDirty = value),
  SET_COMPETENCIA_DE: (state, value) => (state.competenciaDe = value),
  SET_COMPETENCIA_ATE: (state, value) => (state.competenciaAte = value),
  SET_COMPETENCIA: (state, value) => (state.competencia = value),
  SET_CATEGORIA_SERVICOS_SELECIONADA: (state, value) => (state.categoriaServicosSelecionada = value),
  SET_SERVICO_SELECIONADO: (state, value) => (state.servicoSelecionado = value),
  SET_REGIONAL_SELECIONADA: (state, value) => (state.regionalSelecionada = value),
  SET_MUNICIPIO: (state, value) => (state.municipio = value),
  SET_KPI_ORDENACAO: (state, value) => (state.kpiOrdenacao = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
