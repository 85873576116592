import { onlyAdminUsers } from '@/router/guards'

export default [{
      name: 'Importações de Dados',
      path: 'importacoes-dados/historico',
      component: () => import('@/views/administracao/importacoes-dados/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações de Dados', exact: false }
        ])
      },
      beforeEnter: onlyAdminUsers
    },
    {
      name: 'Importações de Dados Adicionar',
      path: 'importacoes-dados/add',
      component: () => import('@/views/administracao/importacoes-dados/Add.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações de Dados', to: '/administracao/importacoes-dados/historico', exact: true },
          { text: 'Adicionar', exact: false },
        ])
      },
      beforeEnter: onlyAdminUsers
    },
    {
      name: 'Importações de Dados Logs',
      path: 'importacoes-dados/logs/:id',
      component: () => import('@/views/administracao/importacoes-dados/Logs.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Administração', to: '/administracao', exact: true },
          { text: 'Importações de Dados', to: '/administracao/importacoes-dados/historico', exact: true },
          { text: 'Logs', exact: false },
        ])
      },
      beforeEnter: onlyAdminUsers
    }
]